<template>
  <div class="material">
    <div v-loading="loading" class="box" element-loading-background="rgba(0, 0, 0, 0)">
      <div :class="total!=0?'list-box':'middle-box'">
        <div v-for="i in materialList" :key="i.id" class="item">
          <el-tooltip class="item" effect="dark" :content="i.name" placement="top" :disabled="!showTip">
            <div class="name" style="overflow: hidden" @mouseover="textRange">{{ i.name }}</div>
          </el-tooltip>

          <div class="down">
            <el-button type="primary" round size="small" @click="downLoad(i)">下载</el-button></div>
        </div>
        <el-empty v-if="total==0" description="暂无数据" :image="require('@/assets/images/empty.png')" />
      </div>
      <div v-if="total>0" class="pageBox">
        <div style="text-align: right;" class="page">
          <el-pagination
            background
            :current-page="currentPage"
            :page-size="pageSize"
            layout=" prev, pager, next, jumper"
            :total="total"
            @current-change="currentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { datasList } from '../../api/study'
import { mapState } from 'vuex'

export default {
  name: 'Material',
  created() {
    // this.onLoad()
    const obj = {
      pageSize: this.pageSize,
      pageNum: this.currentPage,
      classId: this.$store.state.user.userInfo.classId,
      classOutlineId: this.current,
      sonId: this.$store.state.currentNode.sonId
    }
    datasList(obj).then(res => {
      if (res.code === 200) {
        this.loading = false
        this.materialList = res.rows
        this.total = res.total
        console.log('子组件的load信息', res)
      }
    })
  },
  computed: {
    ...mapState({
      current: state => state.currentNode.id
    })
  },
  watch: {
    current(val) {
      console.log(val)
      this.onLoad()
    }
  },
  data() {
    return {
      loading: true,
      materialList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      currentClassOutLineId: '',
      showTip: false
    }
  },
  methods: {
    downLoad(data) {
      this.$download.name(data.url)
    },
    onLoad(data) {
      console.log(this.current)
      const obj = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        classId: this.$store.state.user.userInfo.classId,
        classOutlineId: this.current,
        sonId: this.$store.state.currentNode.sonId,
        ...data
      }
      datasList(obj).then(res => {
        if (res.code === 200) {
          this.loading = false
          this.materialList = res.rows
          this.total = res.total
          console.log('子组件的load信息', res)
        }
      })
    },
    currentChange(val) {
      this.currentPage = val
      this.onLoad()
    },
    textRange(el) {
      console.log(el)
      const textContent = el.target
      const targetW = textContent.getBoundingClientRect().width
      const range = document.createRange()
      range.setStart(textContent, 0)
      range.setEnd(textContent, textContent.childNodes.length)
      const rangeWidth = range.getBoundingClientRect().width
      console.log(rangeWidth > targetW)
      this.showTip = rangeWidth > targetW
    }
  }
}
</script>

<style scoped lang="less">
    /*/deep/.el-tree--highlight-current,/deep/.el-tree-node.is-current > .el-tree-node__content{*/
    /*    background: red;*/
    /*}*/
    .material{
        .box{
            width: 770px;
            min-height: 680px;
            /*background: #ffffff;*/
            margin: 10px;
            >.list-box{
              min-height: 550px;
                padding-top: 13px;
                >.item{
                    display: flex;
                    justify-content: space-between;
                    width: 730px;
                    height: 42px;
                    /*background: #EEF5FB;*/
                    background: white;
                    margin:10px 20px;
                  border-radius: 5px;
                    >.name{
                        padding-left: 20px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 42px;
                      width: 500px;
                      text-align: left;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                    /deep/.el-button--small.is-round{
                        padding: 9px 30px;
                    }
                    >.down
                    {
                        padding:5px 20px 0 0;
                    }
                }
                >.item:hover{
                    cursor: pointer;
                }
            }
            >.middle-box {
                padding-top: 200px;
            }
        }
        .pageBox{
            width: 770px;
            height: 40px;
            margin-top: 20px;
            position: relative;
            .page{
                position: absolute;
                right: 20px;
                bottom: 10px;
                //border: 1px solid #E8E8E8;
                width: auto;
            }
            //::v-deep.el-pagination{
            //    .el-pager{
            //        .number{
            //            color: #999999;
            //        }
            //        .active{
            //            color: #39A5EF;
            //        }
            //        .more{
            //            color: #999999;
            //        }
            //    }
            //}
        }
    }

</style>
